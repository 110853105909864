<template>
  <b-card>
    <div class="custom-container">
      <h2 class="card-title font-weight-bold text-white-50 py-2">
        Market de Beneficios
      </h2>
      <hr />
      <div class="row" style="gap: 1rem">
        <div class="col-12">
          <!-- linar-style cursor glow -->
          <CardTable :data="dataCards" />
        </div>
      </div>

      <!-- search -->
      <div class="d-flex justify-content-center align-items-center py-5">
        <div class="input-container">
          <input
            id="inputSearch"
            class="form-control border"
            v-model="searchBeneficios"
            type="search"
            placeholder="👋¿Qué quieres canjear hoy?🚀"
          />
          <div
            id="icon-search"
            class="d-flex align-items-center justify-content-center p-2 h3"
          >
            <i class="fas fa-search"></i>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div
          class="col-12 col-md-3 col-lg-2 d-flex flex-column mb-3 mb-lg-0"
          style="gap: 1rem"
        >
          <!-- actions -->
          <div class="d-flex flex-column align-items-center">
            <b-form-select
              value-field="value"
              text-field="label"
              v-model="filterBeneficios"
              :options="listFilters"
            >
              <template v-slot:first>
                <b-form-select-option :value="undefined" disabled>
                  -- Filtrar --</b-form-select-option
                >
              </template>
            </b-form-select>
            <vs-button
              v-if="persona.rol.nivel >= 3"
              size="large"
              class="w-100"
              to="/helex/managerMarket"
            >
              <i class="fas fa-gear mr-2"></i>
              Admin. Market
            </vs-button>
          </div>

          <!-- recomendaciones -->
          <!-- <div class="row" style="gap: 0.5rem">
            <div v-for="r in 3" :key="r" class="col-12 p-3 bg-dark rounded">
              <vs-avatar circle warn class="border mb-1">
                <i class="far fa-lightbulb"></i>
              </vs-avatar>
              <h6 class="text-light font-weight-bold">
                Lorem ipsum dolor sit amet.
              </h6>
              <small class="text-white-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus corrupti omnis quis neque facilis eius veritatis quam
              </small>
            </div>
          </div> -->
        </div>
        <div class="col">
          <!-- list beneficios  -->
          <div
            class="d-flex flex-wrap justify-content-center justify-content-lg-start"
            style="gap: 1rem"
          >
            <vs-card
              :key="beneficio.id"
              v-for="beneficio in filterShowBeneficios"
              :id="`popover-${beneficio.id}`"
              class="custom-card-canjes"
            >
              <template #title>
                <div class="d-flex justify-content-between">
                  <vs-button flat success size="mini" class="text-capitalize">
                    {{ beneficio.categoria.nombre }}
                  </vs-button>

                  <!-- icon visibilidad -->
                  <vs-avatar
                    icon
                    circle
                    size="30"
                    :danger="beneficio.visibilidadAreaId !== null"
                    :success="beneficio.visibilidadAreaId === null"
                  >
                    <i
                      style="font-size: 15px"
                      :class="`fas ${
                        beneficio.visibilidadAreaId === null
                          ? 'fa-earth-americas '
                          : 'fa-lock'
                      }`"
                    ></i>
                  </vs-avatar>
                </div>
                <h3>{{ beneficio.nombre }}</h3>
              </template>
              <template #img>
                <img
                  style="min-height: 162.89px; max-height: 162.89px"
                  :src="beneficio.imagenPreview ?? `/img/image_cursos_alt.png`"
                  alt=""
                />
              </template>
              <template #text>
                <p
                  class="text-light"
                  style="
                    height: 50px;
                    min-height: 50px;
                    max-height: 50px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    line-height: 1.2;
                  "
                >
                  {{ beneficio.descripcion }}
                </p>
              </template>
              <template #interactions>
                <vs-button danger>
                  <img
                    style="width: 20px"
                    loading="lazy"
                    src="/img/coins.png"
                    alt="coins"
                  />
                  <span class="span ml-2 font-weight-bold">{{
                    beneficio.coins
                  }}</span>
                </vs-button>
              </template>
            </vs-card>
          </div>
          <!-- popovers -->
          <b-popover
            :key="beneficio.id"
            v-for="beneficio in filterShowBeneficios"
            :target="`popover-${beneficio.id}`"
            triggers="hover focus"
          >
            <template #title>
              <h3 class="custom-text-truncate-title text-capitalize">
                {{ beneficio.nombre }}
              </h3>
            </template>
            <h3 v-if="beneficio.stock !== beneficio.canjes">
              Disponibles: {{ beneficio.stock - beneficio.canjes }}
            </h3>
            <h3 v-else>
              <b-badge variant="warning">Agotado</b-badge>
            </h3>
            <p
              class="custom-text-truncate-p text-white-50"
              style="font-size: 13px"
            >
              {{ beneficio.descripcion }}
            </p>
            <vs-button
              success
              :disabled="
                beneficio.canjes === beneficio.stock ||
                totalCoins < beneficio.coins
              "
              block
              @click="handleModalOpen(beneficio)"
            >
              <i class="fas fa-circle-check mr-2"></i>
              Canjear
            </vs-button>
          </b-popover>
        </div>
      </div>
    </div>

    <!-- modal generate ticket -->
    <b-modal
      id="generateTicket"
      size="md"
      hide-footer
      hide-header
      body-class="body-modal-custom bg-dark"
      no-close-on-backdrop
    >
      <section class="d-flex justify-content-center position-relative">
        <!-- close modal -->
        <vs-button
          @click="closedModalTicket"
          circle
          icon
          danger
          class="position-absolute"
          style="top: -0.5rem; right: -0.5rem"
        >
          <i class="fas fa-circle-xmark"></i>
        </vs-button>
        <main class="ticket-system">
          <div class="top">
            <h1 class="title">
              Espera un segundo, tu ticket se está imprimiendo.
            </h1>

            <!-- download -->
            <!-- <vs-button>
              <i class="fas fa-download mr-1"></i> Descargar
            </vs-button> -->
            <div class="printer" />
          </div>
          <div class="receipts-wrapper">
            <div class="receipts">
              <div class="receipt">
                <img width="100" src="/img/HXLogoBlack.png" alt="logo_helex" />
                <div class="beneficio text-uppercase">
                  <h2>{{ dataCanje.nombre }}</h2>
                </div>
                <div class="details">
                  <div class="item">
                    <span>Coins</span>
                    <h3>
                      {{ dataCanje.coins }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins"
                      />
                    </h3>
                  </div>
                  <div class="item">
                    <span>Ticket No.</span>
                    <h3>HELEX01</h3>
                  </div>
                  <div class="item">
                    <span>Fecha</span>
                    <h3>
                      {{ this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DD"), }}
                    </h3>
                  </div>
                  <div class="item">
                    <span>Hora</span>
                    <h3>
                      {{ this.$moment
            .tz(new Date(), "America/Bogota")
            .format("hh:mm"), }}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="receipt qr-code">
                <img class="qr" v-if="qrUrl" :src="qrUrl" alt="QR Code" />
                <!-- <svg
                  class="qr"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 29.938 29.938"
                >
                  <path
                    d="M7.129 15.683h1.427v1.427h1.426v1.426H2.853V17.11h1.426v-2.853h2.853v1.426h-.003zm18.535 12.83h1.424v-1.426h-1.424v1.426zM8.555 15.683h1.426v-1.426H8.555v1.426zm19.957 12.83h1.427v-1.426h-1.427v1.426zm-17.104 1.425h2.85v-1.426h-2.85v1.426zm12.829 0v-1.426H22.81v1.426h1.427zm-5.702 0h1.426v-2.852h-1.426v2.852zM7.129 11.406v1.426h4.277v-1.426H7.129zm-1.424 1.425v-1.426H2.852v2.852h1.426v-1.426h1.427zm4.276-2.852H.002V.001h9.979v9.978zM8.555 1.427H1.426v7.127h7.129V1.427zm-5.703 25.66h4.276V22.81H2.852v4.277zm14.256-1.427v1.427h1.428V25.66h-1.428zM7.129 2.853H2.853v4.275h4.276V2.853zM29.938.001V9.98h-9.979V.001h9.979zm-1.426 1.426h-7.127v7.127h7.127V1.427zM0 19.957h9.98v9.979H0v-9.979zm1.427 8.556h7.129v-7.129H1.427v7.129zm0-17.107H0v7.129h1.427v-7.129zm18.532 7.127v1.424h1.426v-1.424h-1.426zm-4.277 5.703V22.81h-1.425v1.427h-2.85v2.853h2.85v1.426h1.425v-2.853h1.427v-1.426h-1.427v-.001zM11.408 5.704h2.85V4.276h-2.85v1.428zm11.403 11.405h2.854v1.426h1.425v-4.276h-1.425v-2.853h-1.428v4.277h-4.274v1.427h1.426v1.426h1.426V17.11h-.004zm1.426 4.275H22.81v-1.427h-1.426v2.853h-4.276v1.427h2.854v2.853h1.426v1.426h1.426v-2.853h5.701v-1.426h-4.276v-2.853h-.002zm0 0h1.428v-2.851h-1.428v2.851zm-11.405 0v-1.427h1.424v-1.424h1.425v-1.426h1.427v-2.853h4.276v-2.853h-1.426v1.426h-1.426V7.125h-1.426V4.272h1.426V0h-1.426v2.852H15.68V0h-4.276v2.852h1.426V1.426h1.424v2.85h1.426v4.277h1.426v1.426H15.68v2.852h-1.426V9.979H12.83V8.554h-1.426v2.852h1.426v1.426h-1.426v4.278h1.426v-2.853h1.424v2.853H12.83v1.426h-1.426v4.274h2.85v-1.426h-1.422zm15.68 1.426v-1.426h-2.85v1.426h2.85zM27.086 2.853h-4.275v4.275h4.275V2.853zM15.682 21.384h2.854v-1.427h-1.428v-1.424h-1.427v2.851zm2.853-2.851v-1.426h-1.428v1.426h1.428zm8.551-5.702h2.853v-1.426h-2.853v1.426zm1.426 11.405h1.427V22.81h-1.427v1.426zm0-8.553h1.427v-1.426h-1.427v1.426zm-12.83-7.129h-1.425V9.98h1.425V8.554z"
                  />
                </svg> -->
                <div class="description">
                  <p>Mostrar código QR cuando se solicite.</p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </b-modal>
  </b-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CardTable from "../../../components/cardTable/CardTable.vue";
const Swal = require("sweetalert2");
export default {
  name: "market",
  components: {
    CardTable,
  },
  data() {
    return {
      persona: {},
      qrUrl: "",
      filterBeneficios: "todos",
      filterCategorias: "",
      searchBeneficios: "",
      listFilters: [
        { value: "todos", label: "Todos" },
        { value: "publico", label: "Publicos" },
        { value: "area", label: "Mi area" },
      ],
      listaItems: [
        {
          id: 1,
          nombre: "dia libre",
          descripcion: "descanso",
          categoriaId: "Tiempo Libre",
          imagenPreview: null,
          coins: 20,
          stock: 20,
          canjes: null,
          visibilidadAreaId: false,
          createdAt: null,
          responsableId: null,
          estado: true,
          categoria: {
            nombre: "",
          },
        },
      ],
      dataCanje: {
        id: 1,
        nombre: "dia libre",
        descripcion: "descanso",
        categoriaId: "Tiempo Libre",
        imagenPreview: null,
        coins: 20,
        stock: 20,
        visibilidadAreaId: false,
        createdAt: null,
        responsableId: null,
        estado: true,
        categoria: {
          nombre: "",
        },
      },
      personaUbicacion: {
        area: {
          id: null,
        },
      },
      dataCards: [
        {
          title: "🍔Snacks y Alimentos",
          value: () => {},
          iconName: "pizza-slice",
          color: "rgb(255, 130, 0)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.filterCategorias = "snacks y alimentos";
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.filterCategorias = "";
          },
        },
        {
          title: "🎉Diversión y Entretenimiento",
          value: () => {},
          iconName: "gamepad",
          color: "rgba(71, 136, 255, 1)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.filterCategorias = "diversión y entretenimiento";
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.filterCategorias = "";
          },
        },
        {
          title: "📚Educación",
          value: () => {},
          iconName: "book",
          color: "rgb(23, 201, 100)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.filterCategorias = "educación";
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.filterCategorias = "";
          },
        },
        {
          title: "🏅Deporte y Salud",
          value: () => {},
          iconName: "heart-pulse",
          color: "rgb(242, 19, 93)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.filterCategorias = "deporte y salud";
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.filterCategorias = "";
          },
        },
        {
          title: "🏖️Tiempo Libre",
          value: () => {},
          iconName: "umbrella-beach",
          color: "#8e44ad",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.filterCategorias = "tiempo libre";
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.filterCategorias = "";
          },
        },
        {
          title: "👔Ropa y Calzado",
          value: () => {},
          iconName: "vest-patches",
          color: "#00b894",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.filterCategorias = "ropa y calzado";
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.filterCategorias = "";
          },
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listaItems = [];
    await this.getPersonaUbicacion(this.persona.id);
    await this.getData();
    // this.dataCards = this.showFilterDataCards();
    this.$isLoading(false);
  },
  methods: {
    ...mapMutations({
      setTotalCoins: "setTotalCoins",
      setTotalCanjes: "setTotalCanjes",
    }),
    updateTotalCoins(newValue) {
      this.setTotalCoins(newValue);
    },
    updateTotalCanjes(newValue) {
      this.setTotalCanjes(newValue);
    },
    async addCanjesLog(item) {
      try {
        let newCanje = {
          BeneficioId: item.id,
          Beneficio: item.nombre,
          PersonaId: this.persona.id,
          AreaId: this.personaUbicacion.area.id,
          Categoria: item.categoria.nombre,
          Coins: String(item.coins),
          Fecha: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };

        console.log(newCanje);
        let res = await this.$store.dispatch("hl_post", {
          path: "CanjesMarketlog/",
          data: newCanje,
        });
        console.log("add log...", res);
        if (res.success) {
          this.$vs.notification({
            //duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "success",
            position: "top-right",
            title: "Canjeado con exito!",
          });

          let result = await this.newCanje(item.id);

          if (result.success) {
            let index = this.listaItems.indexOf(item);
            if (this.listaItems[index].canjes === null) {
              this.listaItems[index].canjes = 1;
            } else {
              this.listaItems[index].canjes++;
            }

            this.updateTotalCoins(this.totalCoins - item.coins);
            this.updateTotalCanjes(this.totalCanjes + item.coins);
          }
        }
      } catch (error) {
        this.$vs.notification({
          //duration: "none",
          progress: "auto",
          icon: `<i class='fas fa-circle-xmark'></i>`,
          color: "danger",
          position: "top-right",
          title: error,
        });
        console.log("err", error);
        return null;
      }
    },
    async generarQR(item) {
      const datos = `Nombre del Beneficio: ${item.nombre}\nCoins: ${
        item.coins
      }\nNúmero de Ticket: HELEX01\nFecha: ${this.$moment
        .tz(new Date(), "America/Bogota")
        .format("YYYY-MM-DD")}\nHora: ${this.$moment
        .tz(new Date(), "America/Bogota")
        .format("hh:mm")}`;

      const apiEndpoint = "https://api.qrserver.com/v1/create-qr-code/";
      const queryParams = `?data=${encodeURIComponent(datos)}`;
      this.qrUrl = `${apiEndpoint}${queryParams}`;
    },
    handleModalOpen(item) {
      Swal.fire({
        title: "Seguro de canjear?",
        text: "Este proceso es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#186bdd",
        cancelButtonColor: "rgb(242, 19, 93)",
        confirmButtonText: "Si, canjear!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        // console.log(result);
        if (result.isConfirmed) {
          if (item.canjes !== item.stock && this.totalCoins >= item.coins) {
            this.dataCanje = item;
            this.generarQR(item);
            this.$bvModal.show("generateTicket");

            this.addCanjesLog(item);
          }
        }
      });
    },
    closedModalTicket() {
      this.$bvModal.hide("generateTicket");
    },
    showFilterDataCards() {
      let list = this.dataCards || [];

      return Array.from(
        new Set(this.listaItems.map((beneficio) => beneficio.categoria.nombre))
      ).map(
        (categoria) =>
          list.filter(
            (card) => card.title.slice(2).toLowerCase() === categoria
          )[0]
      );
    },
    async newCanje(beneficioId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "RegistroBeneficiosMarket/NewCanje/" + beneficioId,
        });
        console.log("new canje...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `RegistroBeneficiosMarket/List/${this.personaUbicacion.area.id}/${this.persona.empresa.id}`,
        });
        console.log("list beneficios...", res);
        if (res.success) {
          this.listaItems = res.data.filter(
            (beneficio) => beneficio.estado !== 0
          );
          console.log("update list", this.listaItems);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getPersonaUbicacion(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + personaId,
        });
        console.log("persona ubicacion...", res);
        if (res.cargo) {
          this.personaUbicacion = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  computed: {
    ...mapGetters({
      getTotalCoins: "getTotalCoins",
      getTotalCanjes: "getTotalCanjes",
    }),
    totalCoins() {
      return this.getTotalCoins;
    },
    totalCanjes() {
      return this.getTotalCanjes;
    },
    filterShowBeneficios() {
      let list = this.listaItems || [];

      if (this.filterBeneficios !== "todos") {
        list = list.filter((beneficio) =>
          this.filterBeneficios === "publico"
            ? beneficio.visibilidadAreaId === null
            : beneficio.visibilidadAreaId !== null
        );
      }

      if (this.searchBeneficios !== "") {
        list = list.filter((beneficio) =>
          beneficio.nombre
            .toLowerCase()
            .includes(this.searchBeneficios.toLowerCase())
        );
      }

      if (this.filterCategorias !== "") {
        list = list.filter((beneficio) =>
          beneficio.categoria.nombre
            .toLowerCase()
            .includes(this.filterCategorias.toLowerCase())
        );
      }
      return list;
    },
  },
};
</script>

<style scoped>
/* .dark .bg-market {
  background-image: url(/img/blob-scene-haikei.svg);
  background-size: cover;
  background-position: top;

} */
/* .bg-market{
  background-image: url(/img/blob-scene-haikei-l.svg);
  background-size: cover;
  background-position: top;
} */
/* .bg-market {
  background-image: url(/img/blob-scene-haikei.svg);
  background-size: cover;
  background-position: top;
} */

.input-container {
  position: relative;
}

#inputSearch {
  width: 380px;
  max-width: 430px;
  height: 60px;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all 0.5s;
  border: none;
}

#inputSearch:focus {
  width: 430px;
  box-shadow: inset 0 0 0 2px rgb(242, 19, 93);
}
#inputSearch::placeholder {
  font-size: 1.2rem;
  font-weight: 700;
  opacity: 0.8;
}

#icon-search {
  position: absolute;
  transition: 0.5s;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px !important;
}

/* ticket sistem */
.ticket-system {
  max-width: 350px;
  .top {
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      font-weight: 400;
      font-size: 1.6em;
      text-align: left;
      margin-left: 20px;
      margin-bottom: 50px;
      color: #fff;
    }
    .printer {
      width: 90%;
      height: 20px;
      border: 5px solid #fff;
      border-radius: 10px;
      box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .receipts-wrapper {
    overflow: hidden;
    margin-top: -10px;
    padding-bottom: 10px;
  }

  .receipts {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translateY(-510px);

    animation-duration: 2.5s;
    animation-delay: 500ms;
    animation-name: print;
    animation-fill-mode: forwards;

    .receipt {
      padding: 25px 30px;
      text-align: left;
      min-height: 200px;
      width: 88%;
      background-color: #fff;
      border-radius: 10px 10px 20px 20px;
      box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.2);

      .beneficio {
        margin: 30px 0;

        h2 {
          font-weight: 700;
          font-size: 1.5em;
          color: rgba(28, 28, 28, 0.9);
          margin: 0;
        }
      }

      .details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          display: flex;
          flex-direction: column;
          min-width: 70px;

          span {
            font-size: 0.8em;
            color: rgba(28, 28, 28, 0.7);
            font-weight: 500;
          }
          h3 {
            margin-top: 10px;
            margin-bottom: 25px;
            color: #000;
          }
        }
      }

      &.qr-code {
        height: 110px;
        min-height: unset;
        position: relative;
        border-radius: 20px 20px 10px 10px;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          background: linear-gradient(to right, #fff 50%, #3f32e5 50%);
          background-size: 22px 4px, 100% 4px;
          height: 4px;
          width: 90%;
          display: block;
          left: 0;
          right: 0;
          top: -1px;
          position: absolute;
          margin: auto;
        }

        .qr {
          width: 70px;
          height: 70px;
        }

        .description {
          margin-left: 20px;
          p {
            margin: 0;
            font-weight: 400;
            color: rgba(28, 28, 28, 0.7);
          }
        }
      }
    }
  }
}

@keyframes print {
  0% {
    transform: translateY(-510px);
  }
  35% {
    transform: translateY(-395px);
  }
  70% {
    transform: translateY(-140px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
